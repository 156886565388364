import * as React from "react"
import { Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react";
import styled from "styled-components"

import Callout from './callout'

const components = {
  Callout
}

const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const FooterLeft = styled.div`
  flex: 2;
`

const FooterRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

const FooterLink = styled.a`
`

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <MDXProvider components={components}>
      <div className="global-wrapper" data-is-root-path={isRootPath}>
        <header className="global-header">{header}</header>
        <main>{children}</main>
        <Footer>
          <FooterLeft>
            © {new Date().getFullYear()} Anton Ivanopoulos.
          </FooterLeft>
          <FooterRight>
            <FooterLink href="https://twitter.com/aivanopoulos" target="_blank" rel="noopener noreferrer">Twitter</FooterLink>
            <FooterLink href="mailto:hello@antonivanopoulos.com" target="_blank" rel="noopener noreferrer">Contact</FooterLink>
          </FooterRight>
        </Footer>
      </div>
    </MDXProvider>
  )
}

export default Layout
